<template>
  <!--begin: Language bar -->
  <div class="topbar-item">
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 text-decoration-none"
      dropup
      right
      no-flip
      :no-caret="true"
    >
      <template v-slot:button-content>
        <img class="h-20px w-20px rounded-sm" :src="flag" alt="" />
      </template>
      <b-dropdown-text class="app-lang-dropdown" tag="div">
        <KTDropdownLanguage @language-changed="onChangeLanguage" />
      </b-dropdown-text>
    </b-dropdown>
  </div>
  <!--end: Language bar -->
</template>

<script>
import apolloMixin from "@/mixins/apollo.mixin";
import { updateLanguage } from "../gql/mutations";
import { mapGetters, mapMutations } from "vuex";
import i18nService from "@/services/i18n.service";
import KTDropdownLanguage from "@/layouts/main/extras/dropdown/DropdownLanguage.vue";

export default {
  name: "Lang",
  components: {
    KTDropdownLanguage,
  },

  mixins: [apolloMixin],

  data() {
    return {
      language: "",
      languages: i18nService.languages,
    };
  },

  computed: {
    ...mapGetters(["isAuthenticatedUser", "userLanguage"]),

    flag() {
      return this.languages.find((item) => item.lang === this.language).flag;
    },
  },

  created() {
    this.onChangeLanguage();
  },

  methods: {
    ...mapMutations(["SET_LANGUAGE"]),

    async onChangeLanguage() {
      this.language = i18nService.getActiveLanguage();

      const isLanguageMatches = this.language === this.userLanguage;

      if (this.isAuthenticatedUser && !isLanguageMatches) {
        const updatedLanguage = await this.$post(
          updateLanguage,
          { language: this.language },
          { withNotify: false }
        );

        if (updatedLanguage) this.SET_LANGUAGE(updatedLanguage.updateProfile);
      }
    },
  },
};
</script>

<style lang="scss">
.topbar-item {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    .b-dropdown-text {
      padding: 0;
    }
  }
}

.app-lang-dropdown {
  min-width: 175px;
}

.navi-text {
  font-weight: 400;
}
</style>
