<template>
  <div
    data-cy="signup-page"
    class="register-page d-flex justify-content-center flex-column align-items-center"
  >
    <div class="mb-11 pt-lg-0 register-page__video">
      <h3
        class="font-weight-bolder text-dark font-size-h1 font-size-h1-lg register-page__video-title"
      >
        {{ $t("title.auth.videoTitle") }}
      </h3>
      <div class="register-page__video-player">
        <iframe
          width="1280"
          height="720"
          src="https://www.youtube.com/embed/YRUogAOn-0w"
          title="Atmosfera. Реферальна програма"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>

    <div class="accordion register-page__faq" role="tablist">
      <b-card no-body v-for="(item, i) in faq" :key="i">
        <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle="`accordion-${i}`">
          <div class="card-header-title">{{ item.title }}</div>
          <div class="card-header-icon">
            <SvgIcon iconFolder="Navigation" iconName="plus" iconColor="#000000" />
          </div>
        </b-card-header>
        <b-collapse :id="`accordion-${i}`" visible accordion="my-accordion" role="tabpanel">
          <div class="register-page__faq-content" v-html="item.content"></div>
        </b-collapse>
      </b-card>
    </div>

    <div class="auth-content">
      <div class="auth-form">
        <div class="mb-11 pt-lg-0">
          <h3 class="font-weight-bolder text-dark font-size-h1 font-size-h1-lg">
            {{ $t("title.auth.signUp") }}
          </h3>

          <div class="text-muted font-weight-bold font-size-h4">
            {{ $t("page.signUp.description") }}
          </div>
        </div>

        <b-form
          data-cy="signup-form"
          class="signup-form"
          novalidate
          @submit.stop.prevent="onSubmitForm"
        >
          <InputElement
            v-model="form.firstName"
            data-cy="first-name-input"
            class="mb-10"
            size="lg"
            required
            :with-error="errors.firstName"
            :error-message="$t('validation.required')"
            :label="$t('label.firstName')"
            @blur="removeErrorClass('firstName')"
            @input="removeErrorClass('firstName')"
          />

          <InputElement
            v-model="form.lastName"
            data-cy="last-name-input"
            class="mb-10"
            size="lg"
            required
            :with-error="errors.lastName"
            :error-message="$t('validation.required')"
            :label="$t('label.lastName')"
            @blur="removeErrorClass('lastName')"
            @input="removeErrorClass('lastName')"
          />

          <CleaveElement
            :value="phoneWithPlus"
            :options="cleaveOptions"
            data-cy="phone-input"
            class="mb-10"
            size="lg"
            required
            :label="$t('label.phone')"
            :with-error="errors.phone"
            :error-message="phoneErrorMsg"
            @input="onChangePhone"
            @blur="removeErrorClass('phone')"
          />

          <InputElement
            v-model="form.email"
            data-cy="email-input"
            class="mb-10"
            size="lg"
            required
            input-type="email"
            :with-error="errors.email"
            :error-message="emailErrorMsg"
            :label="$t('label.email')"
            @blur="removeErrorClass('email')"
            @input="removeErrorClass('email')"
          />

          <TextareaElement
            v-model="form.comment"
            data-cy="comment-input"
            class="mb-8"
            :label="$t('label.comment')"
            :placeholder="$t('page.signUp.enterCompanyName')"
            :with-error="errors.comment"
            :error-message="commentErrorMsg"
            @blur="removeErrorClass('comment')"
            @input="removeErrorClass('comment')"
          />

          <div class="d-flex align-items-center flex-wrap mb-4">
            <b-button
              data-cy="submit-btn"
              type="submit"
              variant="primary"
              class="auth-btn submit-btn font-weight-bolder font-size-h6 px-15 py-4"
            >
              {{ $t("button.signUp") }}
            </b-button>

            <b-link
              data-cy="cancel-btn"
              class="btn auth-btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
              :to="loginRoute"
            >
              {{ $t("button.back") }}
            </b-link>
          </div>

          <div>
            {{ $t("page.signUp.agreeTerms") }}
            <template v-for="(item, index) of contractOfferItems">
              <a
                :key="`link-${index}`"
                data-cy="rules-link"
                :href="item.url"
                :target="getTarget(item.target)"
                class="ml-0.5"
              >
                <span>
                  {{ $t(item.translate) }}
                </span>
              </a>

              <span v-if="index < contractOfferItems.length - 1" :key="index">
                {{ $t("page.signUp.and") }}
              </span>
            </template>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import i18nService from "@/services/i18n.service.js";
import agentSignUp from "@/configs/agentSignUp.config.json";

import InputElement from "@/components/form/InputElement";
import MultiselectElement from "@/components/form/MultiselectElement";
import TextareaElement from "@/components/form/TextareaElement";
import CleaveElement from "@/components/form/CleaveElement";
import SvgIcon from "@/components/svg/SvgIcon";

export default {
  name: "SignUp",

  components: {
    InputElement,
    MultiselectElement,
    TextareaElement,
    CleaveElement,
    SvgIcon,
  },

  mixins: [apolloMixin],

  data: () => ({
    form: {
      firstName: "",
      lastName: "",
      phone: "38",
      email: "",
      comment: "",
    },
    errors: {
      firstName: false,
      lastName: false,
      phone: false,
      email: false,
      comment: false,
    },
    cleaveOptions: {
      phone: true,
      phoneRegionCode: "UA",
      delimiter: "-",
    },
    phoneMinLength: 11,
    phoneMaxLength: 13,
    commentMaxLength: 255,
  }),

  computed: {
    contractOfferItems() {
      return agentSignUp.contractOffer.items;
    },

    phoneWithPlus() {
      return `+${this.form.phone}`;
    },

    phoneNumberLength() {
      const phoneRegExp = /\D/g;

      return this.form.phone.replace(phoneRegExp, "").length;
    },

    emailErrorMsg() {
      return !this.form.email ? this.$t("validation.required") : this.$t("validation.mustBeEmail");
    },

    phoneErrorMsg() {
      return !this.phoneNumberLength
        ? this.$t("validation.required")
        : this.$t("validation.phoneLength");
    },

    commentErrorMsg() {
      return this.$t("validation.commentMaxLength", { length: this.commentMaxLength });
    },

    loginRoute() {
      return { name: "Login" };
    },

    locale() {
      return this.$i18n.locale;
    },

    faq() {
      return [
        {
          title: this.$t('layout.auth.faq_1_title'),
          content: this.$t('layout.auth.faq_1_content'),
        },
        {
          title: this.$t('layout.auth.faq_2_title'),
          content: this.$t('layout.auth.faq_2_content'),
        },
        {
          title: this.$t('layout.auth.faq_3_title'),
          content: this.$t('layout.auth.faq_3_content'),
        },
      ];
    }
  },

  methods: {
    ...mapActions(["signUp"]),

    getTarget(target) {
      return target ? target : "";
    },

    onChangePhone(phone) {
      this.form.phone = phone;
      this.removeErrorClass("phone");
    },

    removeErrorClass(key) {
      this.errors[key] = false;
    },

    isValidForm() {
      const emailRegExp = /.+@.+\..+/i;

      for (let key in this.errors) {
        this.errors[key] = false;
      }

      this.errors.firstName = !this.form.firstName;
      this.errors.lastName = !this.form.lastName;
      this.errors.phone = !this.phoneNumberLength;
      this.errors.phone =
        this.phoneNumberLength < this.phoneMinLength ||
        this.phoneNumberLength > this.phoneMaxLength;
      this.errors.email = !this.form.email || !this.form.email.match(emailRegExp);
      this.errors.comment = this.form.comment.length > this.commentMaxLength;

      return !Object.values(this.errors).some((error) => error);
    },

    async onSubmitForm() {
      if (!this.isValidForm()) return;

      const language = i18nService.getActiveLanguage();

      const formData = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        phone: this.form.phone,
        email: this.form.email,
        comment: this.form.comment,
        language,
      };

      await this.signUp(formData);
      await this.trackEvent();
    },

    trackEvent() {
      this.$gtag.event("sucess", { event_category: "register" });
    },
  },
};
</script>

<style lang="scss">
.register-page {
  width: 100%;

  &__video {
    width: 100%;
    max-width: 800px;

    &-title {
      text-align: center;
      margin-bottom: 2rem;
    }
    &-player {
      position: relative;
      padding-bottom: 60%;

      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }

  &__faq {
    width: 100%;
    max-width: 800px;
    margin: 24px 0 56px 0;

    .card {
      border: none;
      border-radius: 0;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      &-header {
        min-height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0 !important;
        font-size: 24px;
        line-height: 32px;
        color: #000;
        font-weight: 500;
        background: transparent !important;
        border-top: 1px solid rgba(26, 26, 38, 0.1);
        border-bottom: 1px solid rgba(26, 26, 38, 0.1) !important;

        .card-header-icon {
          transition: all 0.3s ease 0s;
        }

        .svg-icon {
          padding: 0 !important;
        }

        svg {
          fill: #000;
          width: 32px !important;
          height: 32px !important;

          g {
            fill: #000;
          }
        }

        &.not-collapsed {
          .card-header-icon {
            transform: rotate(-135deg);
          }
        }
      }
    }

    &-content {
      padding: 16px 0 0 0;
      font-size: 18px;
      line-height: 24px;

      ul, ol {
        margin-left: 36px;

        li {
          margin-bottom: 12px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      ul {
        li {
          list-style: none;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: -28px;
            top: 0;
            width: 20px;
            height: 20px;
            background: url('/media/svg/icons/Navigation/Check.svg') no-repeat;
          }
        }
      }
    }
  }
}
</style>
