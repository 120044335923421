<template>
  <div class="d-flex flex-column flex-root">
    <div
      id="kt_login"
      class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-7 p-lg-15"
        :style="{ background: `url(${backgroundImagePath}) repeat #1a1a27` }"
      >
        <!--  #1e1f2d -->
        <!--begin: Aside Container -->
        <div class="d-flex flex-row-fluid flex-column justify-content-between">
          <!--begin: Aside header -->
          <div class="aside-header">
            <b-link :to="loginRoute" class="aside-logo">
              <img :src="logoPath" alt="logo" />
            </b-link>
          </div>

          <!--end: Aside header -->
          <!--begin: Aside content -->
          <div class="flex-column-fluid d-flex flex-column justify-content-center">
            <h3 class="font-size-h1 mt-lg-0 mt-8 mb-1 text-white">
              {{ $t("layout.auth.title") }}
            </h3>
          </div>
          <!--end: Aside content -->
          <!--begin: Aside footer for desktop -->
          <div
            class="desktop-aside-footer flex-column-auto justify-content-between align-items-center mt-5"
          >
            <div class="opacity-70 font-weight-bold text-white">© 2021 ATMOSFERA</div>
            <div class="d-flex">
              <div class="aside-lang">
                <Lang class="ml-0 mt-0" />
              </div>
            </div>
          </div>
          <!--end: Aside footer for desktop -->
        </div>
        <!--end: Aside Container -->
      </div>
      <!--begin::Aside-->

      <!--begin::Content-->
      <div class="flex-row-fluid d-flex flex-column position-relative p-7">
        <div class="view-wrap flex-column-fluid">
          <router-view />
        </div>

        <div class="mobile-footer flex-column-auto justify-content-between align-items-center mt-7">
          <div class="font-weight-bold text-muted">© 2021 ATMOSFERA</div>
          <div class="d-flex">
            <div class="aside-lang">
              <Lang class="ml-0 mt-0" />
            </div>
          </div>
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Lang from "@/layouts/main/header/Lang";

export default {
  name: "Auth",
  components: {
    Lang,
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),

    ...mapGetters(["getClasses"]),

    backgroundImagePath() {
      return process.env.BASE_URL + "media/bg/bg2.png";
    },

    logoPath() {
      return process.env.BASE_URL + this.layoutConfig("self.logo.light");
    },

    loginRoute() {
      return { name: "Login" };
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/sass/pages/login/login-1";
</style>
